import React, { useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./sites/home";
import Projects, { ProjectRoutes } from "./sites/projects";
import Persons from "./sites/persons";
import Overview from "./sites/mix-view";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/projects">
          <ProjectRoutes />
        </Route>
        <Route path="/persons">
          <Persons />
        </Route>
        <Route path="/overview">
          <Overview />
        </Route>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="*">
          <h1>404</h1>
        </Route>
      </Switch>
    </Router>
  );
}

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
