import styled from "styled-components";
import Besucher, { Kontakt } from "../components/besucher";
import Nav from "../components/nav";
import Sponsor from "../components/sponsor";
import mapImg from "./hallenplan.png";
import { useEffect, useState } from "react";
import { PubAPI } from "../api";
import { ProjectList } from "../generated/src/api/proto/api";
import { Link } from "react-router-dom";
import pinIcon from "./icon_place_blue.png";

const Jumbotron = styled.section`
  background-color: #fff;
`;

const Home = () => {
  return (
    <>
      <Nav />
      <Jumbotron className="jumbotron text-center">
        <Besucher />
        <LagePlan />
        <Kontakt />
        <Sponsor />
      </Jumbotron>
    </>
  );
};

export default Home;

const Location = styled.div<{ x: number; y: number }>`
  position: absolute;
  top: calc(${(props) => props.y + "%"} - 35px);
  left: calc(${(props) => props.x + "%"} - 17.5px);
  z-index: 100;
`;

// pin size is hardcoded as offset in the location calc above so that the tip is at the specified position
const Pin = styled.img`
  width: 35px;
  height: 35px;
`;

const LagePlan = () => {
  const [project, setProject] = useState<ProjectList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetProjects({
          year: 2023,
        });
        if (!ignore) {
          setProject(res.projects);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const pLocation = project.map((p) => {
    if (p.PosX === 0 && p.PosY === 0) {
      return null;
    }
    return (
      <Link to={`/projects/${p.ID}`} title={p.Title}>
        <Location x={p.PosX} y={p.PosY}>
          <Pin src={pinIcon}></Pin>
        </Location>
      </Link>
    );
  });

  return (
    <div className="container text-center mb-5">
      <h1>Lageplan</h1>
      <p className="lead text-muted">Finde alle Stände auf einen Blick.</p>
      <div style={{ position: "relative" }}>
        {pLocation}
        <img src={mapImg} style={{ width: "100%" }} alt="Lageplan"></img>
      </div>
    </div>
  );
};
