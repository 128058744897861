import {
  faExternalLinkAlt,
  faSearch,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useMemo, useState } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import styled from "styled-components";
import Nav from "../components/nav";
import Fuse from "fuse.js";
import Sponsor from "../components/sponsor";
import Scroll from "react-scroll";
import { useMediaQuery } from "react-responsive";
import Select, { ActionMeta } from "react-select";
import { ErrorBoundary } from "react-error-boundary";
import { GetProfessionName, PubAPI } from "../api";
import { User, UserList } from "../generated/src/api/proto/api";
import { UserImage } from "../components/userCard";

export interface FilterType {
  year: SelectType[];
  company: SelectType[];
  profession: SelectType[];
  projectyear: SelectType[];
}

export interface SelectType {
  value: string;
  label: string;
}

const Persons = () => {
  let match = useRouteMatch();

  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<FilterType>({
    company: [],
    profession: [],
    year: [],
    projectyear: [
      {
        /* value: DateTime.now().year.toString(), */
        /* label: DateTime.now().year.toString(), */
        value: "2023",
        label: "2023",
      },
    ],
  });

  const [users, setUsers] = useState<UserList[]>([]);
  useEffect(() => {
    let ignore = false;
    const fetchData = async () => {
      try {
        const res = await PubAPI.GetUsers({});
        if (!ignore) {
          setUsers(res.users);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    return () => {
      ignore = true;
    };
  }, []);

  const fuse = useMemo(
    () =>
      new Fuse(users, {
        keys: [
          "Users.FirstName",
          "Users.LastName",
          "Users.Profession.FemaleTitle",
          "Users.Profession.MaleTitle",
          "Companies.Name",
        ],
        threshold: 0.3,
      }),
    [users]
  );

  let userResults =
    search === "" ? users : fuse.search(search).map((p) => p.item);

  const tempYear = userResults.map((U) => {
    return U.Users?.ApprenticeshipYear.toString() ?? "";
  });

  const tempFirma = userResults.map((U) => {
    return U.Companies?.Name ?? "";
  });

  const tempBeruf = userResults.map((U) => {
    return U.Users?.Profession?.FemaleTitle ?? "";
  });

  const tempProjectyear = userResults.map((U) => {
    return U.Projects?.Year.toString() ?? "";
  });

  function onlyUnique(value: any, index: any, array: string | any[]) {
    return array.indexOf(value) === index;
  }

  tempProjectyear.push(new Date().getFullYear().toString());
  const yearFilter: SelectType[] = tempYear
    .filter(onlyUnique)
    .sort()
    .map((y) => ({ value: y, label: y }));

  const firmaFilter: SelectType[] = tempFirma
    .filter(onlyUnique)
    .sort()
    .map((y) => ({ value: y, label: y }));

  const berufFilter: SelectType[] = tempBeruf
    .filter(onlyUnique)
    .sort()
    .map((y) => ({ value: y, label: y }));

  const projectyearFilter: SelectType[] = tempProjectyear
    .filter(onlyUnique)
    .sort()
    .map((y) => ({ value: y, label: y }));

  if (filter.year.length !== 0) {
    userResults = userResults.filter((u) =>
      filter.year
        .map((y) => y.value)
        .includes(u.Users?.ApprenticeshipYear.toString() ?? "")
    );
  }

  if (filter.company.length !== 0) {
    userResults = userResults.filter((u) =>
      filter.company.map((y) => y.value).includes(u.Companies?.Name ?? "")
    );
  }

  if (filter.profession.length !== 0) {
    userResults = userResults.filter((u) =>
      filter.profession
        .map((y) => y.value)
        .includes(u.Users?.Profession?.FemaleTitle ?? "")
    );
  }

  if (filter.projectyear.length !== 0) {
    userResults = userResults.filter((u) =>
      filter.projectyear
        .map((y) => y.value)
        .includes(u.Projects?.Year.toString() ?? "")
    );
  }

  return (
    <>
      <Nav />
      <Switch>
        <Route path={`${match.path}/:personID`}>
          <div></div>
        </Route>
        <Route path={match.path}>
          <Title
            search={search}
            setSearch={setSearch}
            yearFilter={yearFilter}
            setFilter={setFilter}
            firmaFilter={firmaFilter}
            berufFilter={berufFilter}
            projectyearFilter={projectyearFilter}
            filter={filter}
          />
          <PersonenOverview user={userResults} />
          {/* <ProjectOverview projects={data.projects} image={data.image} /> */}
        </Route>
      </Switch>
      <Sponsor></Sponsor>
    </>
  );
};

const Jumbotron = styled.section`
  background-color: #fff;
  margin-bottom: 0;
`;

const Searchbar = styled.div`
  padding-left: 15px;
  max-width: 800px;
  margin-top: 30px;
`;

const SearchInput = styled.input`
  border: 0;
  height: 50px;
  border-radius: 2px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.25);
  font-size: 1em;
  font-weight: 700;
  :active {
    box-shadow: none !important;
    outline: none;
  }
  :focus {
    box-shadow: none !important;
    outline: none;
  }
`;

const SearchIcon = styled.a`
  font-size: 1.5rem;
  max-width: 800px;
`;

function Title(props: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  yearFilter: SelectType[];
  firmaFilter: SelectType[];
  berufFilter: SelectType[];
  filter: FilterType;
  projectyearFilter: SelectType[];
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}) {
  const [showResults, setShowResults] = React.useState(true);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const showFilter = () => setShowResults(!showResults);
  return (
    <Jumbotron className="jumbotron text-center">
      <div className="container">
        <h1>Lernende</h1>
        <p className="lead text-muted">
          Finde Lernende, die an den Projekten teilnehmen und kontaktiere sie
          via Webkonferenz, wenn sie online sind.
        </p>
        <ErrorBoundary fallbackRender={() => <></>}>
          <Scroll.Element name="SearchBar"></Scroll.Element>
          <Searchbar className=" mx-auto row align-items-center border border-primary rounded-pill">
            <SearchIcon className="col-auto">
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <div className="col">
              <SearchInput
                className=""
                type="search"
                placeholder="Lehrberuf, Lehrfirma, Name, ..."
                value={props.search}
                onChange={(i) => props.setSearch(i.currentTarget.value)}
                onFocus={(k) => {
                  if (isMobile) {
                    Scroll.scroller.scrollTo("SearchBar", {
                      duration: 500,
                      delay: 100,
                      smooth: true,
                      offset: 0,
                    });
                  }
                }}
              />
            </div>
            <SearchIcon className="col-auto">
              <FontAwesomeIcon icon={faFilter} onClick={showFilter} />
            </SearchIcon>
          </Searchbar>
        </ErrorBoundary>
        <ErrorBoundary fallbackRender={() => <></>}>
          {showResults && (
            <Results
              yearFilter={props.yearFilter}
              setFilter={props.setFilter}
              firmaFilter={props.firmaFilter}
              berufFilter={props.berufFilter}
              projectyearFilter={props.projectyearFilter}
              filter={props.filter}
            />
          )}
        </ErrorBoundary>
      </div>
    </Jumbotron>
  );
}

const FilterBox = styled.div`
  display: flex;
  justify-content: center;
  max-width: 900px;
`;

const FilterItem = styled.div`
  margin: 10px;
  width: 200px;
`;

const Results = (props: {
  yearFilter: SelectType[];
  firmaFilter: SelectType[];
  berufFilter: SelectType[];
  projectyearFilter: SelectType[];
  filter: FilterType;
  setFilter: React.Dispatch<React.SetStateAction<FilterType>>;
}) => {
  return (
    <FilterBox className="mx-auto row align-items-center">
      <FilterItem>
        <Select
          closeMenuOnSelect={false}
          placeholder="Lehrjahr"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              year: [...option],
            }));
          }}
          isMulti
          options={props.yearFilter}
        />
      </FilterItem>
      <FilterItem>
        <Select
          closeMenuOnSelect={true}
          placeholder="Firma"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              company: [...option],
            }));
          }}
          isMulti
          options={props.firmaFilter}
        />
      </FilterItem>
      <FilterItem>
        <Select
          closeMenuOnSelect={true}
          placeholder="Beruf"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              profession: [...option],
            }));
          }}
          isMulti
          options={props.berufFilter}
        />
      </FilterItem>
      <FilterItem>
        <Select
          closeMenuOnSelect={true}
          placeholder="Jahr"
          onChange={(
            option: readonly SelectType[],
            actionMeta: ActionMeta<SelectType>
          ) => {
            props.setFilter((prevState) => ({
              ...prevState,
              projectyear: [...option],
            }));
          }}
          isMulti
          options={props.projectyearFilter}
          defaultValue={[
            {
              /* value: DateTime.now().year.toString(), */
              /* label: DateTime.now().year.toString(), */
              value: "2023",
              label: "2023",
            },
          ]}
        />
      </FilterItem>
    </FilterBox>
  );
};

function PersonenOverview(props: { user: UserList[] }) {
  const user = props.user;

  return (
    <div className="album py-5 bg-light">
      <div className="container">
        <div className="row">
          {user.map((u) => {
            return <UserCard key={u.Users?.ID} user={u.Users}></UserCard>;
          })}
        </div>
      </div>
    </div>
  );
}

const UserCard = (props: { user: User | undefined }) => {
  const user = props.user;

  if (user === undefined) {
    return <></>;
  }

  return (
    <div className="col-md-4">
      <div className="card mb-4 shadow-sm" id={user.ID}>
        <UserImage user={user} customMaxHeight="350px" objectFit="cover" />
        <div className="card-body">
          <h5 className="card-title">{user.FirstName + " " + user.LastName}</h5>
          {user.ApprenticeshipYear === "" ? (
            <></>
          ) : (
            <p className="mb-0">{user.ApprenticeshipYear} Lehrjahr</p>
          )}
          <p className="mb-0">
            {user.Profession?.ExternalID ? (
              <a
                href={`https://berufsberatung.ch/dyn/show/1900?id=${user.Profession.ExternalID}`}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="text-primary mr-2"
                  icon={faExternalLinkAlt}
                ></FontAwesomeIcon>
                {GetProfessionName(user)}
              </a>
            ) : (
              <div>{GetProfessionName(user)}</div>
            )}
          </p>
          <p>
            {/* {props.user.usercompanyname ? (
              <a
                href={props.user.usercompanywebsite}
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="text-primary mr-2"
                  icon={faExternalLinkAlt}
                ></FontAwesomeIcon>
                {props.user.usercompanyname}
              </a>
            ) : (
              <div>{props.user.usercompanyname}</div>
            )} */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Persons;
