import styled from "styled-components";
import { User } from "../generated/src/api/proto/api";
import { GetProfessionName } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ImageComponent } from "../components/image";

const Placeholder = styled.div`
  background-color: #f8f9fa;
  width: 250px;
  height: 350px;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageSizeBox = styled.div`
  height: 350px;
  flex-grow: 1;
`;

export const UserCard = (props: { user: User; dynamicSize: boolean }) => {
  const user = props.user;
  return (
    <div className={props.dynamicSize ? "col-auto" : "col-md-6"}>
      <div className="card mb-4 shadow-sm" id={user.ID}>
        <div className="row no-gutters">
          <div className="col-auto">
            <ImageSizeBox>
              <UserImage user={user} objectFit="cover" />
            </ImageSizeBox>
          </div>
          <div className="col">
            <div className="card-body d-flex flex-column h-100">
              <h5 className="card-title">
                {user.FirstName + " " + user.LastName}
              </h5>
              {user.ApprenticeshipYear !== "" ? (
                <p className="mb-0">{user.ApprenticeshipYear} Lehrjahr</p>
              ) : null}
              {user.Profession?.ExternalID ? (
                <a
                  href={`https://berufsberatung.ch/dyn/show/1900?id=${user.Profession.ExternalID}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    className="text-primary mr-2"
                    icon={faExternalLinkAlt}
                  ></FontAwesomeIcon>
                  {GetProfessionName(user)}
                </a>
              ) : (
                <div>{GetProfessionName(user)}</div>
              )}
              <p className="mt-0">
                {/* {props.user.usercompanywebsite !== "" ? (
                  <a
                    href={props.user.usercompanywebsite}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FontAwesomeIcon
                      className="text-primary mr-2"
                      icon={faExternalLinkAlt}
                    ></FontAwesomeIcon>
                    {props.user.usercompanyname}
                  </a>
                ) : (
                  <>{props.user.usercompanyname}</>
                )} */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const UserImage = (props: {
  user: User;
  customMaxHeight?: string;
  customMaxWidth?: string;
  objectFit?: "cover" | "contain";
}) => {
  const user = props.user;
  if (user.ProfileImage !== undefined && user.ProfileImage.BaseURL !== "") {
    console.log(user.ProfileImage);
    return (
      <ImageComponent
        img={user.ProfileImage}
        customMaxHeight={props.customMaxHeight}
        customMaxWidth={props.customMaxWidth}
        objectFit={props.objectFit}
        fill={true}
      />
    );
  } else {
    return (
      <Placeholder>
        <svg
          className="bd-placeholder-img"
          width="150"
          height="150"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="xMidYMid"
          focusable="false"
          role="img"
          aria-label="Placeholder: Thumbnail"
          viewBox="0 0 512 512"
        >
          <path
            d="M437.02,330.98c-27.883-27.882-61.071-48.523-97.281-61.018C378.521,243.251,404,198.548,404,148
C404,66.393,337.607,0,256,0S108,66.393,108,148c0,50.548,25.479,95.251,64.262,121.962
c-36.21,12.495-69.398,33.136-97.281,61.018C26.629,379.333,0,443.62,0,512h40c0-119.103,96.897-216,216-216s216,96.897,216,216
h40C512,443.62,485.371,379.333,437.02,330.98z M256,256c-59.551,0-108-48.448-108-108S196.449,40,256,40
c59.551,0,108,48.448,108,108S315.551,256,256,256z"
          />
        </svg>
      </Placeholder>
    );
  }
};
