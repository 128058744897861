/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import Long from "long";
import _m0 from "protobufjs/minimal";

export const protobufPackage = "api.proto";

export interface ProjectList {
  ID: string;
  Title: string;
  Year: number;
  Description: string;
  CoverImage: Image | undefined;
  PosX: number;
  PosY: number;
  InternalProjectNumber: string;
  ProjectType: number;
}

export interface ProjectDetail {
  ID: string;
  Title: string;
  Year: number;
  Description: string;
  VideoUrl: string;
  FileUrl: string;
  Image: Image[];
  Users: User[];
  Companies: Company[];
  InternalProjectNumber: string;
  ProjectType: number;
  PosX: number;
  PosY: number;
}

export interface User {
  ID: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  ProfileImage: Image | undefined;
  ApprenticeshipYear: string;
  Profession: Profession | undefined;
}

export interface UserList {
  Users: User | undefined;
  Projects: ProjectList | undefined;
  Companies: Company | undefined;
}

export interface Profession {
  ID: string;
  ExternalID: number;
  MaleTitle: string;
  FemaleTitle: string;
}

export interface Company {
  ID: string;
  Name: string;
  Description: string;
  Image: Image | undefined;
  WebsiteUrl: string;
}

export interface Image {
  BaseURL: string;
  Width: number;
  Height: number;
  BlurHash: string;
  DimensionsProcessed: number[];
}

export interface getProjectsRequest {
  year: number;
  limit: number;
  withCoverImage: boolean;
}

export interface getProjectsResponse {
  projects: ProjectList[];
}

export interface getProjectDetailRequest {
  id: string;
}

export interface getProjectDetailResponse {
  project: ProjectDetail | undefined;
}

export interface getUserRequest {
  year: number;
  withProjects: boolean;
}

export interface getUserResponse {
  users: UserList[];
}

function createBaseProjectList(): ProjectList {
  return {
    ID: "",
    Title: "",
    Year: 0,
    Description: "",
    CoverImage: undefined,
    PosX: 0,
    PosY: 0,
    InternalProjectNumber: "",
    ProjectType: 0,
  };
}

export const ProjectList = {
  encode(message: ProjectList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Title !== "") {
      writer.uint32(18).string(message.Title);
    }
    if (message.Year !== 0) {
      writer.uint32(24).int32(message.Year);
    }
    if (message.Description !== "") {
      writer.uint32(34).string(message.Description);
    }
    if (message.CoverImage !== undefined) {
      Image.encode(message.CoverImage, writer.uint32(42).fork()).ldelim();
    }
    if (message.PosX !== 0) {
      writer.uint32(48).int64(message.PosX);
    }
    if (message.PosY !== 0) {
      writer.uint32(56).int64(message.PosY);
    }
    if (message.InternalProjectNumber !== "") {
      writer.uint32(66).string(message.InternalProjectNumber);
    }
    if (message.ProjectType !== 0) {
      writer.uint32(72).int32(message.ProjectType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Title = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.Year = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.Description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.CoverImage = Image.decode(reader, reader.uint32());
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.PosX = longToNumber(reader.int64() as Long);
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.PosY = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.InternalProjectNumber = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.ProjectType = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProjectList>, I>>(base?: I): ProjectList {
    return ProjectList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProjectList>, I>>(object: I): ProjectList {
    const message = createBaseProjectList();
    message.ID = object.ID ?? "";
    message.Title = object.Title ?? "";
    message.Year = object.Year ?? 0;
    message.Description = object.Description ?? "";
    message.CoverImage = (object.CoverImage !== undefined && object.CoverImage !== null)
      ? Image.fromPartial(object.CoverImage)
      : undefined;
    message.PosX = object.PosX ?? 0;
    message.PosY = object.PosY ?? 0;
    message.InternalProjectNumber = object.InternalProjectNumber ?? "";
    message.ProjectType = object.ProjectType ?? 0;
    return message;
  },
};

function createBaseProjectDetail(): ProjectDetail {
  return {
    ID: "",
    Title: "",
    Year: 0,
    Description: "",
    VideoUrl: "",
    FileUrl: "",
    Image: [],
    Users: [],
    Companies: [],
    InternalProjectNumber: "",
    ProjectType: 0,
    PosX: 0,
    PosY: 0,
  };
}

export const ProjectDetail = {
  encode(message: ProjectDetail, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Title !== "") {
      writer.uint32(18).string(message.Title);
    }
    if (message.Year !== 0) {
      writer.uint32(24).int32(message.Year);
    }
    if (message.Description !== "") {
      writer.uint32(34).string(message.Description);
    }
    if (message.VideoUrl !== "") {
      writer.uint32(42).string(message.VideoUrl);
    }
    if (message.FileUrl !== "") {
      writer.uint32(50).string(message.FileUrl);
    }
    for (const v of message.Image) {
      Image.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.Users) {
      User.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.Companies) {
      Company.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.InternalProjectNumber !== "") {
      writer.uint32(82).string(message.InternalProjectNumber);
    }
    if (message.ProjectType !== 0) {
      writer.uint32(88).int32(message.ProjectType);
    }
    if (message.PosX !== 0) {
      writer.uint32(96).int64(message.PosX);
    }
    if (message.PosY !== 0) {
      writer.uint32(104).int64(message.PosY);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ProjectDetail {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProjectDetail();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Title = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.Year = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.Description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.VideoUrl = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.FileUrl = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.Image.push(Image.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.Users.push(User.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.Companies.push(Company.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.InternalProjectNumber = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.ProjectType = reader.int32();
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.PosX = longToNumber(reader.int64() as Long);
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.PosY = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ProjectDetail>, I>>(base?: I): ProjectDetail {
    return ProjectDetail.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ProjectDetail>, I>>(object: I): ProjectDetail {
    const message = createBaseProjectDetail();
    message.ID = object.ID ?? "";
    message.Title = object.Title ?? "";
    message.Year = object.Year ?? 0;
    message.Description = object.Description ?? "";
    message.VideoUrl = object.VideoUrl ?? "";
    message.FileUrl = object.FileUrl ?? "";
    message.Image = object.Image?.map((e) => Image.fromPartial(e)) || [];
    message.Users = object.Users?.map((e) => User.fromPartial(e)) || [];
    message.Companies = object.Companies?.map((e) => Company.fromPartial(e)) || [];
    message.InternalProjectNumber = object.InternalProjectNumber ?? "";
    message.ProjectType = object.ProjectType ?? 0;
    message.PosX = object.PosX ?? 0;
    message.PosY = object.PosY ?? 0;
    return message;
  },
};

function createBaseUser(): User {
  return {
    ID: "",
    FirstName: "",
    LastName: "",
    Gender: "",
    ProfileImage: undefined,
    ApprenticeshipYear: "",
    Profession: undefined,
  };
}

export const User = {
  encode(message: User, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.FirstName !== "") {
      writer.uint32(18).string(message.FirstName);
    }
    if (message.LastName !== "") {
      writer.uint32(26).string(message.LastName);
    }
    if (message.Gender !== "") {
      writer.uint32(34).string(message.Gender);
    }
    if (message.ProfileImage !== undefined) {
      Image.encode(message.ProfileImage, writer.uint32(42).fork()).ldelim();
    }
    if (message.ApprenticeshipYear !== "") {
      writer.uint32(58).string(message.ApprenticeshipYear);
    }
    if (message.Profession !== undefined) {
      Profession.encode(message.Profession, writer.uint32(50).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): User {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.FirstName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.LastName = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.Gender = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.ProfileImage = Image.decode(reader, reader.uint32());
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.ApprenticeshipYear = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.Profession = Profession.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.ID = object.ID ?? "";
    message.FirstName = object.FirstName ?? "";
    message.LastName = object.LastName ?? "";
    message.Gender = object.Gender ?? "";
    message.ProfileImage = (object.ProfileImage !== undefined && object.ProfileImage !== null)
      ? Image.fromPartial(object.ProfileImage)
      : undefined;
    message.ApprenticeshipYear = object.ApprenticeshipYear ?? "";
    message.Profession = (object.Profession !== undefined && object.Profession !== null)
      ? Profession.fromPartial(object.Profession)
      : undefined;
    return message;
  },
};

function createBaseUserList(): UserList {
  return { Users: undefined, Projects: undefined, Companies: undefined };
}

export const UserList = {
  encode(message: UserList, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.Users !== undefined) {
      User.encode(message.Users, writer.uint32(10).fork()).ldelim();
    }
    if (message.Projects !== undefined) {
      ProjectList.encode(message.Projects, writer.uint32(18).fork()).ldelim();
    }
    if (message.Companies !== undefined) {
      Company.encode(message.Companies, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UserList {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUserList();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.Users = User.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Projects = ProjectList.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.Companies = Company.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UserList>, I>>(base?: I): UserList {
    return UserList.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<UserList>, I>>(object: I): UserList {
    const message = createBaseUserList();
    message.Users = (object.Users !== undefined && object.Users !== null) ? User.fromPartial(object.Users) : undefined;
    message.Projects = (object.Projects !== undefined && object.Projects !== null)
      ? ProjectList.fromPartial(object.Projects)
      : undefined;
    message.Companies = (object.Companies !== undefined && object.Companies !== null)
      ? Company.fromPartial(object.Companies)
      : undefined;
    return message;
  },
};

function createBaseProfession(): Profession {
  return { ID: "", ExternalID: 0, MaleTitle: "", FemaleTitle: "" };
}

export const Profession = {
  encode(message: Profession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.ExternalID !== 0) {
      writer.uint32(16).int32(message.ExternalID);
    }
    if (message.MaleTitle !== "") {
      writer.uint32(26).string(message.MaleTitle);
    }
    if (message.FemaleTitle !== "") {
      writer.uint32(34).string(message.FemaleTitle);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Profession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseProfession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.ExternalID = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.MaleTitle = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.FemaleTitle = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Profession>, I>>(base?: I): Profession {
    return Profession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Profession>, I>>(object: I): Profession {
    const message = createBaseProfession();
    message.ID = object.ID ?? "";
    message.ExternalID = object.ExternalID ?? 0;
    message.MaleTitle = object.MaleTitle ?? "";
    message.FemaleTitle = object.FemaleTitle ?? "";
    return message;
  },
};

function createBaseCompany(): Company {
  return { ID: "", Name: "", Description: "", Image: undefined, WebsiteUrl: "" };
}

export const Company = {
  encode(message: Company, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ID !== "") {
      writer.uint32(10).string(message.ID);
    }
    if (message.Name !== "") {
      writer.uint32(18).string(message.Name);
    }
    if (message.Description !== "") {
      writer.uint32(26).string(message.Description);
    }
    if (message.Image !== undefined) {
      Image.encode(message.Image, writer.uint32(34).fork()).ldelim();
    }
    if (message.WebsiteUrl !== "") {
      writer.uint32(42).string(message.WebsiteUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Company {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCompany();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ID = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.Name = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.Description = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.Image = Image.decode(reader, reader.uint32());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.WebsiteUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Company>, I>>(base?: I): Company {
    return Company.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Company>, I>>(object: I): Company {
    const message = createBaseCompany();
    message.ID = object.ID ?? "";
    message.Name = object.Name ?? "";
    message.Description = object.Description ?? "";
    message.Image = (object.Image !== undefined && object.Image !== null) ? Image.fromPartial(object.Image) : undefined;
    message.WebsiteUrl = object.WebsiteUrl ?? "";
    return message;
  },
};

function createBaseImage(): Image {
  return { BaseURL: "", Width: 0, Height: 0, BlurHash: "", DimensionsProcessed: [] };
}

export const Image = {
  encode(message: Image, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.BaseURL !== "") {
      writer.uint32(10).string(message.BaseURL);
    }
    if (message.Width !== 0) {
      writer.uint32(16).int32(message.Width);
    }
    if (message.Height !== 0) {
      writer.uint32(24).int32(message.Height);
    }
    if (message.BlurHash !== "") {
      writer.uint32(34).string(message.BlurHash);
    }
    writer.uint32(42).fork();
    for (const v of message.DimensionsProcessed) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Image {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseImage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.BaseURL = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.Width = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.Height = reader.int32();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.BlurHash = reader.string();
          continue;
        case 5:
          if (tag === 40) {
            message.DimensionsProcessed.push(reader.int32());

            continue;
          }

          if (tag === 42) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.DimensionsProcessed.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Image>, I>>(base?: I): Image {
    return Image.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Image>, I>>(object: I): Image {
    const message = createBaseImage();
    message.BaseURL = object.BaseURL ?? "";
    message.Width = object.Width ?? 0;
    message.Height = object.Height ?? 0;
    message.BlurHash = object.BlurHash ?? "";
    message.DimensionsProcessed = object.DimensionsProcessed?.map((e) => e) || [];
    return message;
  },
};

function createBasegetProjectsRequest(): getProjectsRequest {
  return { year: 0, limit: 0, withCoverImage: false };
}

export const getProjectsRequest = {
  encode(message: getProjectsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.year !== 0) {
      writer.uint32(8).int32(message.year);
    }
    if (message.limit !== 0) {
      writer.uint32(16).int32(message.limit);
    }
    if (message.withCoverImage === true) {
      writer.uint32(24).bool(message.withCoverImage);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getProjectsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetProjectsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.year = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.limit = reader.int32();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.withCoverImage = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getProjectsRequest>, I>>(base?: I): getProjectsRequest {
    return getProjectsRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getProjectsRequest>, I>>(object: I): getProjectsRequest {
    const message = createBasegetProjectsRequest();
    message.year = object.year ?? 0;
    message.limit = object.limit ?? 0;
    message.withCoverImage = object.withCoverImage ?? false;
    return message;
  },
};

function createBasegetProjectsResponse(): getProjectsResponse {
  return { projects: [] };
}

export const getProjectsResponse = {
  encode(message: getProjectsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.projects) {
      ProjectList.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getProjectsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetProjectsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.projects.push(ProjectList.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getProjectsResponse>, I>>(base?: I): getProjectsResponse {
    return getProjectsResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getProjectsResponse>, I>>(object: I): getProjectsResponse {
    const message = createBasegetProjectsResponse();
    message.projects = object.projects?.map((e) => ProjectList.fromPartial(e)) || [];
    return message;
  },
};

function createBasegetProjectDetailRequest(): getProjectDetailRequest {
  return { id: "" };
}

export const getProjectDetailRequest = {
  encode(message: getProjectDetailRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id !== "") {
      writer.uint32(10).string(message.id);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getProjectDetailRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetProjectDetailRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getProjectDetailRequest>, I>>(base?: I): getProjectDetailRequest {
    return getProjectDetailRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getProjectDetailRequest>, I>>(object: I): getProjectDetailRequest {
    const message = createBasegetProjectDetailRequest();
    message.id = object.id ?? "";
    return message;
  },
};

function createBasegetProjectDetailResponse(): getProjectDetailResponse {
  return { project: undefined };
}

export const getProjectDetailResponse = {
  encode(message: getProjectDetailResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.project !== undefined) {
      ProjectDetail.encode(message.project, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getProjectDetailResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetProjectDetailResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.project = ProjectDetail.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getProjectDetailResponse>, I>>(base?: I): getProjectDetailResponse {
    return getProjectDetailResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getProjectDetailResponse>, I>>(object: I): getProjectDetailResponse {
    const message = createBasegetProjectDetailResponse();
    message.project = (object.project !== undefined && object.project !== null)
      ? ProjectDetail.fromPartial(object.project)
      : undefined;
    return message;
  },
};

function createBasegetUserRequest(): getUserRequest {
  return { year: 0, withProjects: false };
}

export const getUserRequest = {
  encode(message: getUserRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.year !== 0) {
      writer.uint32(8).int32(message.year);
    }
    if (message.withProjects === true) {
      writer.uint32(16).bool(message.withProjects);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getUserRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetUserRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.year = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.withProjects = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getUserRequest>, I>>(base?: I): getUserRequest {
    return getUserRequest.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getUserRequest>, I>>(object: I): getUserRequest {
    const message = createBasegetUserRequest();
    message.year = object.year ?? 0;
    message.withProjects = object.withProjects ?? false;
    return message;
  },
};

function createBasegetUserResponse(): getUserResponse {
  return { users: [] };
}

export const getUserResponse = {
  encode(message: getUserResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.users) {
      UserList.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): getUserResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasegetUserResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.users.push(UserList.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<getUserResponse>, I>>(base?: I): getUserResponse {
    return getUserResponse.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<getUserResponse>, I>>(object: I): getUserResponse {
    const message = createBasegetUserResponse();
    message.users = object.users?.map((e) => UserList.fromPartial(e)) || [];
    return message;
  },
};

export interface PublicService {
  GetProjects(request: DeepPartial<getProjectsRequest>, metadata?: grpc.Metadata): Promise<getProjectsResponse>;
  GetProjectDetail(
    request: DeepPartial<getProjectDetailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<getProjectDetailResponse>;
  GetUsers(request: DeepPartial<getUserRequest>, metadata?: grpc.Metadata): Promise<getUserResponse>;
}

export class PublicServiceClientImpl implements PublicService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.GetProjects = this.GetProjects.bind(this);
    this.GetProjectDetail = this.GetProjectDetail.bind(this);
    this.GetUsers = this.GetUsers.bind(this);
  }

  GetProjects(request: DeepPartial<getProjectsRequest>, metadata?: grpc.Metadata): Promise<getProjectsResponse> {
    return this.rpc.unary(PublicServiceGetProjectsDesc, getProjectsRequest.fromPartial(request), metadata);
  }

  GetProjectDetail(
    request: DeepPartial<getProjectDetailRequest>,
    metadata?: grpc.Metadata,
  ): Promise<getProjectDetailResponse> {
    return this.rpc.unary(PublicServiceGetProjectDetailDesc, getProjectDetailRequest.fromPartial(request), metadata);
  }

  GetUsers(request: DeepPartial<getUserRequest>, metadata?: grpc.Metadata): Promise<getUserResponse> {
    return this.rpc.unary(PublicServiceGetUsersDesc, getUserRequest.fromPartial(request), metadata);
  }
}

export const PublicServiceDesc = { serviceName: "api.proto.PublicService" };

export const PublicServiceGetProjectsDesc: UnaryMethodDefinitionish = {
  methodName: "GetProjects",
  service: PublicServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return getProjectsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = getProjectsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PublicServiceGetProjectDetailDesc: UnaryMethodDefinitionish = {
  methodName: "GetProjectDetail",
  service: PublicServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return getProjectDetailRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = getProjectDetailResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const PublicServiceGetUsersDesc: UnaryMethodDefinitionish = {
  methodName: "GetUsers",
  service: PublicServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return getUserRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = getUserResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata ?? this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata ?? {},
        ...(this.options.transport !== undefined ? { transport: this.options.transport } : {}),
        debug: this.options.debug ?? false,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends { $case: string } ? { [K in keyof Omit<T, "$case">]?: DeepPartial<T[K]> } & { $case: T["$case"] }
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(globalThis.Number.MAX_SAFE_INTEGER)) {
    throw new globalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

export class GrpcWebError extends globalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
