import React from "react";
import logo from "./logo.png";
import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";
import { Container, Nav, NavItem } from "react-bootstrap";
import Navbar from "react-bootstrap/esm/Navbar";

const LogoImg = styled.img`
  height: 50px;
`;
function Navigation() {
  return (
    <Navbar bg="light" expand="md">
      <Container>
        <Link className="navbar-brand" to="/">
          <LogoImg src={logo} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <NavItem>
              <Nav.Link as={NavLink} to="/" exact={true}>
                Home
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/projects">
                Projekte
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/persons">
                Lernende
              </Nav.Link>
            </NavItem>
            <NavItem>
              <Nav.Link as={NavLink} to="/overview">
                Mix-Ansicht
              </Nav.Link>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
