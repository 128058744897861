const Sponsor = () => {
  return (
    <div className="py-4">
      <div className="container d-flex justify-content-around flex-wrap col-6">
        <a
          href="https://www.bank-avera.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_bank-avera.png"
            alt="Sponsor Bank Avera"
          />
        </a>
        <a
          href="http://www.gebauerstiftung.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_gebauer-stiftung.png"
            alt="Sponsor Gebauer Stiftung"
          />
        </a>
        <a
          href="http://www.zh.ch/berufsbildungsfonds"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_kanton-zuerich-bildungsdirektion.png"
            alt="Sponsor Kanton Zürich Bildungsdirektion"
          />
        </a>
        <a
          href="https://www.kgv.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_kgv.png"
            alt="Sponsor KGV"
          />
        </a>
        <a
          href="https://www.zuerioberland-wirtschaft.ch/wirtschaftsregion/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_zuerioberland.png"
            alt="Sponsor Zürioberland Wirtschaft"
          />
        </a>
        <a
          href="http://zueriost.ch/"
          target="_blank"
          rel="noreferrer"
          className="my-3 align-self-center"
        >
          <img
            src="https://lehrlingswettbewerb.ch/fileadmin/templates/images/sponsor_zueriost.png"
            alt="Sponsor Züriost"
          />
        </a>
      </div>
    </div>
  );
};

export default Sponsor;
